import React from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import {  useTransactions, useTransitions, useUser } from '../../data/vistor'
import Loading from '../../Components/Shared/Loading'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useCustomMutation } from '../../hooks/use-custome-mutation';
import client from '../../client';
import { API_ENDPOINTS } from '../../client/endpoints';
import AddNotification from '../../Components/Notification/AddNotification';
import Block from '../../Components/User/Block';
import TransitionsCard from '../../Components/Transistions/TransitionsCard';
import TransactionsCard from '../../Components/Transactions/TransactionsCard';
const convertDays = {
  "sunday": "الأحد",
  "monday": "الإثنين",
  "tuesday": "الثلاثاء",
  "wednesday": "الأربعاء",
  "thursday": "الخميس",
  "friday": "الجمعة",
  "saturday": "السبت"
};

export default function User() {
  const {t} = useTranslation()
  const {id} = useParams()
  const userData = useUser(id)
  const [openAddNotification, setOpenAddNotification] = React.useState(false);
  const closeAddNotification = () => setOpenAddNotification(false);
  const openAddNotificationModel = () => setOpenAddNotification(true);
  const TransactionData = useTransactions({
    from : id, 
    to :id , 
  });

  const transitionData = useTransitions({userId:id})
  const verifyMutation = useCustomMutation({
    mutationFn: client.Users.verify_account,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_USER(id)],
    },
  });
  const [openAdd, setOpenAdd] = React.useState(false);
  const closeAdd = () => setOpenAdd(false);
  const openAddModel = () => setOpenAdd(true);
  const sortedSpecialties = userData.data.specialties && [...userData.data.specialties.map(item=> item.value)].sort((a, b) => {
    if (a === userData.data.mainSpecialty.value) return -1;
    if (b === userData.data.mainSpecialty.value) return 1;
    return 0;
  });
  const isClient = userData.data.type==='client';
  const blockMutation = useCustomMutation({
    mutationFn: client.Users.block_user,
    queryToInvalidate: {
      queryKey: [API_ENDPOINTS.GET_USER(id)],
    },
    onSuccess:()=>{
      closeAdd();
    }
  });
  const handleBlock = ()=>{

    userData.data.isBlocked.value===true ?
    blockMutation.mutate({
      id,
      block:false,
    })
    :openAddModel()
  }
  return (userData.isPending?<Loading/>:
    <div className='flex flex-col gap-5'>
    <div className='w-full bg-card p-5 rounded-md text-white'>
      <div className='flex items-center justify-between gap-5'>
      <div className='flex items-center w-full gap-5'>
        <div className='w-12 h-12 '>
          <img
          className='w-full h-full rounded-full'
            alt="Profile_Image"
            src={userData.data.profileImage}
            loading="lazy"
          />
        </div>
        <div className='flex flex-col gap-2 w-full'>
          <div className='flex justify-between'>
            <h1 className='text-lg text-main-light'>{userData.data.name}</h1>
          </div>
        <span className="h-[2px] w-full bg-gray-600"/>
        <h4 className='text-white'>{t(userData.data.type)}</h4>
        </div>
      </div>
      <button onClick={()=> openAddNotificationModel()} className='px-4 py-2 whitespace-nowrap rounded-md bg-main text-2xl cursor-pointer'>
        {t('send_notification')}
      </button>
      <AddNotification selectedUserIds={[id]} handleClose={closeAddNotification} open={openAddNotification} />
      <button onClick={()=> handleBlock()} className='px-4 py-2 whitespace-nowrap rounded-md bg-main text-2xl cursor-pointer'>
        {userData.data.isBlocked.value===true?t('unblock'):t('block')}
      </button>
      {!userData.data.isVerifiedByCrm && !isClient &&
      <button onClick={()=> verifyMutation.mutate(id)} className='px-4 py-2 whitespace-nowrap rounded-md bg-main text-2xl cursor-pointer'>
        {t('verify')}
      </button>
      }
      </div>
      <div className='flex flex-col gap-3 pt-5'>
        {userData.data.email && <h4><span className='text-xl font-medium'>{t('email')} : </span>{userData.data.email}</h4>}
        {userData.data.phoneNumber && <h4><span className='text-xl font-medium'>{t('phone_number')} : </span>{userData.data.phoneNumber}</h4>}
        {userData.data.address && <h4><span className='text-xl font-medium'>{t('address')} : </span>{userData.data.address}</h4>}
        {userData.data.price > 0 && <h4><span className='text-xl font-medium'>{t('price')} : </span>{userData.data.price}</h4>}
        {userData.data.about && <h4><span className='text-xl font-medium'>{t('about')} : </span>{userData.data.about}</h4>}
      </div>
    </div>
    {!isClient && 
    <div className='flex flex-col gap-2'>
    <h2 className='text-xl font-semibold'>{t('information')}</h2>
      <div className='flex gap-5'>
        <div className='flex flex-col items-center gap-2'>
            <div className='w-20 h-20 bg-card border-main rounded-full text-main !border-2 flex items-center justify-center'>
             <CheckCircleIcon className='text-main-light'/>
            </div>
            <div className='flex flex-col items-center'>
              <h3 className='text-lg font-medium'>{t('available_to_call')}</h3>
              <h3 className='text-main font-medium'>{userData.data.isAvailableToCall?'Yes':'No'}</h3>
            </div>
        </div>
        <div className='flex flex-col items-center gap-2'>
            <div className='w-20 h-20 bg-card border-main rounded-full text-main !border-2 flex items-center justify-center'>
             <CheckCircleIcon className='text-main-light'/>
            </div>
            <div className='flex flex-col items-center'>
              <h3 className='text-lg font-medium'>{t('available_to_visit')}</h3>
              <h3 className='text-main font-medium'>{userData.data.isAvailableToVisit?'Yes':'No'}</h3>
            </div>
        </div>
      </div>
    </div>
    }
    {userData.data.specialties?.length>0 && 
        <div className='flex flex-col gap-2'>
          <h4 className='text-xl font-medium'>{t('specialties')}</h4>
          <div className='flex gap-2'>
            {sortedSpecialties.map((specialty,index) =>
            <div key={index} className='p-2 bg-[#FBE67B] border-main rounded-md text-main !border-2'>
              {specialty}
            </div>
            )}
          </div>
        </div>
        }
        {userData.data.workTimes?.length>0 && 
        <div className='flex flex-col gap-2'>
          <h4 className='text-xl font-medium'>{t('work_time')}</h4>
          <div className='flex flex-col gap-3'>
            {userData.data.workTimes.map((time,index) =>
            <div className='flex gap-2 items-center' key={index}>
               <div className='font-semibold text-main'>
                {convertDays[time.day]} 
               </div>
               <div className='flex gap-2'>
                {time.periods && time.periods.map((period,i) =>
                <div key={i} className='border-main p-2 rounded-xl'>
                  {t('from')} {period.fromTime} {t('to')} {period.toTime}
                </div>
                )}
               </div>
            </div>
            )}
          </div>
        </div>
        }
    
    {userData.data.wallet && !isClient && 
    <>
    {/* <div>
      <button
        className="btn-main-light px-5 py-3 rounded-lg font-bold text-2xl mb-4"
        onClick={openAddModel}
      >
        {t('make_transaction_amount')}
      </button>
    </div> */}
    <div className='w-full bg-card rounded-md text-white'>
          <div className='p-5 flex items-center justify-between'>
            <h3 className='text-2xl text-main-light'>{t('account_balance')}</h3>
          </div>
          <div className='p-5 flex flex-col md:flex-row items-center justify-between'>
              <div className='flex flex-col items-center gap-4'>
                <h3 className='text-xl font-semibold'>{t('withdraw_balance')}</h3>
                <h3 className='text-main-light font-semibold'>{userData.data.wallet.withdrawBalance}</h3>
              </div>
              <div className='flex flex-col items-center gap-4'>
                <h3 className='text-xl font-semibold'>{t('hold_balance')}</h3>
                <h3 className='text-main-light font-semibold'>{userData.data.wallet.holdBalance}</h3>
              </div>
              <div className='flex flex-col items-center gap-4'>
                <h3 className='text-xl font-semibold'>{t('total_balance')}</h3>
                <h3 className='text-main-light font-semibold'>{userData.data.wallet.totalBalance}</h3>
              </div>
          </div>
      </div>
      {openAdd && 
        <Block handleClose={closeAdd} open={openAdd} userData={userData} />
      }
    </>
    }
    {(userData.data.identityImage.front || userData.data.identityImage.back) && 
      <div className='w-full bg-card rounded-md text-white'>
          <div className='p-5 flex items-center justify-between'>
            <h3 className='text-2xl text-main-light'>{t('identify_image')}</h3>
          </div>
          <div className='p-5 flex flex-col md:flex-row gap-5 items-center'>
              <div className='flex flex-col w-full md:w-[500px] h-60 rounded-lg overflow-hidden items-center gap-4'>
                <img className='w-full h-full' src={userData.data.identityImage.front} alt="Front Identity" />
              </div>
              <div className='flex flex-col w-full md:w-[500px] h-60 rounded-lg overflow-hidden items-center gap-4'>
                <img className='w-full h-full' src={userData.data.identityImage.back} alt=" " />
              </div>
          </div>
      </div>
    }
    {transitionData?.data?.data?.length>0  && 
    <div>
      <h1 className='text-center text-3xl font-extrabold pb-5'>{t('transitions')}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {transitionData?.data?.data.map((item, index)=>
          <TransitionsCard item={item} key={index}/>
        )}
      </div>
    </div>
    }
    {TransactionData?.data?.length>0  && (
        <div className="flex flex-col gap-5">
          <h1 className="text-center text-3xl font-extrabold pb-5">{t('transactions')}</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {TransactionData?.data?.map((item, index) => (
              <TransactionsCard item={item} key={index} />
            ))}
          </div>
        </div>)
        }
   </div>
  )
}
